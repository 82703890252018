import { useEffect, useState } from 'react';
import { Alert, Loading, StandardButton, useApi, useFeatureToggle, useInvevoSession } from 'invevo-react-components'
import { useHistory, useLocation } from "react-router-dom"
import { PARENT_ROUTE } from "../home/Home"
import { useMsal } from "@azure/msal-react";
import ClientButton from './ClientButton';

const INVEVO_ORIGIN = "https://www.invevo.com"
const CREDIT_CIRCLE_CLIENT_NAME = "creditcircle"

const LandingPage = () => {
    const [isFetching, setIsFetching] = useState(false)
    const [hasFetched, setHasFetched] = useState(false)
    const [fetchClients, setFetchClients] = useState(false)
    const { isEnabled: featureToggleOn } = useFeatureToggle("landingPage")
    const location = useLocation()

    const api = useApi()
    const history = useHistory()
    const { instance } = useMsal()
    
    const [invevoSession, setInvevoSession, clearInvevoSession] = useInvevoSession()

    const onClientSelected = (client) => {
        window.location.replace(`${PARENT_ROUTE}/search?client=${client}`)
    }

    const getSubdomain = () => window.location.host.split('.')[0]

    useEffect(() => {
        if (featureToggleOn) window.location.replace(INVEVO_ORIGIN)

        const isLogout = new URLSearchParams(location.search).get("logout")
        if (isLogout) {
            onLogoutClick()
            return
        }
        
        clearInvevoSession()
        setFetchClients(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!fetchClients || isFetching || hasFetched) return

        const subdomain = getSubdomain()
        if(subdomain === CREDIT_CIRCLE_CLIENT_NAME) {
            setHasFetched(true)
            setInvevoSession({ clients: [ CREDIT_CIRCLE_CLIENT_NAME ] })
            onClientSelected(CREDIT_CIRCLE_CLIENT_NAME)
            return
        }

        setIsFetching(true)
        api.get(`/api/user-clients`)
            .then(response => {
                const clients = response.data.clients.filter(client => {
                    if(subdomain && (subdomain.toLowerCase() === "travis")) {
                        return client === "tp"
                    }
                    
                    if(subdomain && (subdomain.toLowerCase() === "cityplumbing")) {
                        return client === "ph"
                    }
                    
                    if(subdomain && (subdomain.toLowerCase() === "adeccouk")) {
                        return client === "adecco"
                    }
                    
                    if(subdomain && (subdomain.toLowerCase() === "adeccoglobal")) {
                        return client === "adeccoglobal"
                    }
                    
                    return client !== "tp" && client !== "ph" && client !== "ph" && client !== "adecco"  && client !== "adeccoglobal"
                })
                
                setFetchClients(false)
                setHasFetched(true)
                setInvevoSession({ clients })
                setIsFetching(false)

                if (clients.length === 1) {
                    onClientSelected(clients[0])
                }
            })
            .catch(error => {
                if (error.response.status === 404) {
                    setHasFetched(true)
                    setInvevoSession({ clients: [] })
                    setIsFetching(false)
                }
                else {
                    console.log(error)
                }
            })
    }, [api, hasFetched, isFetching, history, invevoSession.clients, fetchClients, setInvevoSession])

    const onLogoutClick = () => {       
        clearInvevoSession()
        instance.logout({ postLogoutRedirectUri: "/" });
    }

    return (
        <div className='d-flex flex-column h-100'>
            <div className='d-flex h-25 justify-content-center'>
                <img src="/Invevo_Logo_Blue.png" alt="Invevo Logo" />
            </div>
            <div className='d-flex flex-column justify-content-center w-50 align-self-center '>
                <Loading isLoading={isFetching || invevoSession.isLoggedOutFromCient || !hasFetched} colour="blue" >
                    { getSubdomain() === CREDIT_CIRCLE_CLIENT_NAME ? 
                        <></> : 
                        <>
                            { invevoSession.clients && invevoSession.clients.length > 0 ?
                                <div className='d-flex flex-wrap align-self-center justify-content-center'>
                                    {invevoSession.clients.map(client => <ClientButton key={client} client={client} onClick={onClientSelected} />)}
                                </div> :
                                <Alert className="align-self-center" type="warning" iconClasses="fal fa-exclamation-triangle" text="No available clients found for this user" />
                            }
                            <StandardButton
                                className="mt-5 mb-2 align-self-center"
                                iconClasses="fal fa-arrow-left"
                                label="Logout"
                                colour="blue"
                                onClick={onLogoutClick}
                            />
                        </>
                    }
                </Loading>
            </div>
        </div>
    )
}

export default LandingPage
