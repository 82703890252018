import { ReactNode } from "react"
import moment from "moment-timezone"

export type NoteType = "field update" | "comment" | "communication" | "task" | "customer" | "virtual account" | "payment" | "transaction"

export type FieldNote = {
    noteType: "field update"
    entityDisplayName: string
}

export type CommentNote = {
    noteType: "comment"
    entityDisplayName: string
}

export type CommNote = {
    noteType: "communication"
    customerId: string
    inbound: boolean
    emailTrackingId: string
    readByUserId: string
}

export type TaskNote = {
    noteType: "task"
    taskType: string
    taskCompletion: string
}

export type CustomerNote = {
    noteType: "customer"
}

export type VirtualAccountNote = {
    noteType: "virtual account"
}

export type PaymentNote = {
    noteType: "payment"
    transactionRef: string
}

export type TransactionNote = {
    noteType: "transaction"
    transactionRef: string
}

type Note = { noteId: string; noteIds: string[]; noteEpochTime: number; noteType: NoteType; note: ReactNode; userName: string } & (
    | FieldNote
    | CommentNote
    | CommNote
    | TaskNote
    | CustomerNote
    | VirtualAccountNote
    | PaymentNote
    | TransactionNote
)

export default Note

export const convertSqlNoteToNote = (sqlNote: any): Note => {
    const baseNote = {
        noteId: sqlNote.NoteId,
        noteIds: [sqlNote.NoteId],
        noteEpochTime: moment.tz(sqlNote.NoteDate, "Europe/London").valueOf(),
        note: sqlNote.Note,
        userName: sqlNote.UserName
    }

    if (sqlNote.Note && (sqlNote.Note.toLowerCase().startsWith("portal payment") || sqlNote.Note.toLowerCase().startsWith("invevo payment initiated"))) {
        return {
            ...baseNote,
            noteType: "payment",
            transactionRef: sqlNote.TransactionRef
        }
    }

    if (sqlNote.Inbound || (sqlNote.EmailTrackingId && sqlNote.EmailTrackingId !== "")) {
        return {
            ...baseNote,
            noteType: "communication",
            emailTrackingId: sqlNote.EmailTrackingId,
            inbound: sqlNote.Inbound,
            customerId: sqlNote.CustomerId,
            readByUserId: sqlNote.ReadByUserId
        }
    }

    if (sqlNote.TaskType) {
        return {
            ...baseNote,
            noteType: "task",
            taskType: sqlNote.TaskType,
            taskCompletion: sqlNote.TaskCompletion
        }
    }

    if (sqlNote.NoteType && sqlNote.NoteType.toLowerCase() === "customer") {
        return {
            ...baseNote,
            noteType: "customer"
        }
    }

    if (sqlNote.NoteType && sqlNote.NoteType.toLowerCase() === "virtual account") {
        return {
            ...baseNote,
            noteType: "virtual account"
        }
    }

    return {
        ...baseNote,
        noteType: "transaction",
        transactionRef: sqlNote.TransactionRef
    }
}
